<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h4 class="System_admin_text_Product ml-2">CBR - WORKSHEET</h4>
      </v-col>

      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>
      </v-col>

      <v-col cols="4">
        <span class="small-lbl">
          LAST UPDATED BY: {{ this.currentUser }} on: {{ this.lastUpdate }}
          {{ this.lastUpdateTime }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <div class="text-right">
          <b-button-group>
            <!-- <button class="offers-button" @click="openOffers">Offers</button> -->
            <!-- <button class="tasks-button" @click="openTasks">Tasks</button> -->
            <b-button class="uploads-button" @click="openUploads"
              >Uploads</b-button
            >
            <b-button class="save-button" @click="saveFormData">Save</b-button>
            <b-button class="back-button" @click="goBack">Back</b-button>
            <WordExportButton
              templateName="CBR"
              :templateData="worksheetData"
            />
          </b-button-group>
        </div>
      </v-col>
    </v-row>

    <div>
      <v-container>
        <v-form v-model="valid">
          <p>
            The items below are essential elements of the CBR. Go ahead and
            start to record your ideas relating to the types of things you will
            want to be covered about your business in this document.
          </p>

          <v-row>
            <b><label for="">Corporation Details</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              label="Corporation Details"
              v-model="worksheetData.corp_details"
            >
            </b-form-textarea>
            <b><label for="">Corporation Details</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.biz_dob"
            >
            </b-form-textarea>
          </v-row>

          <v-row>
            <b><label for="">Revenue</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.revenue"
            >
            </b-form-textarea>
            <b
              ><label for=""
                >The Reason the Business Exists in the Market</label
              ></b
            >
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.market_reason"
            >
            </b-form-textarea>
          </v-row>
          <v-row>
            <b><label for="">Competition</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.competition"
            >
            </b-form-textarea>
            <b><label for="">Growth Possibilities</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.growth"
            >
            </b-form-textarea>
          </v-row>
          <v-row>
            <b><label for="">Pros & Cons of the Business</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.pros_cons"
            >
            </b-form-textarea>
            <b
              ><label for=""
                >Holes in the Business a New Owner Could Fill</label
              ></b
            >
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.holes"
            >
            </b-form-textarea>

            <v-row></v-row>
            <b><label for="">Deficiencies in the Business</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.deficiencies"
            >
            </b-form-textarea>
            <b><label for="">Current Pipeline of Business</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.pipeline"
            >
            </b-form-textarea>

            <b><label for="">Number of Employees</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.employees"
            >
            </b-form-textarea>
            <b><label for="">Roles of Key Employees</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.key_roles"
            >
            </b-form-textarea>
            <b><label for="">Describe the Role of the Current Owner</label></b>
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.owner_role"
            >
            </b-form-textarea>

            <v-row></v-row>
            <b
              ><label for=""
                >Detail What the New Owner Will Need to Do to Run the
                Business</label
              ></b
            >
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.biz_detail"
            >
            </b-form-textarea>
            <v-row></v-row>
            <b
              ><label for=""
                >Note Any Replacement Costs when the Old Owner (Seller) is
                Replaced</label
              ></b
            >
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.replace_costs"
            >
            </b-form-textarea>

            <v-row></v-row>
            <b
              ><label for=""
                >Highlight Key Assets and Liabilities (Explanation of Key
                Balance Sheet Items)</label
              ></b
            >
            <b-form-textarea
              clearable
              no-resize
              class="wide-box-text"
              v-model="worksheetData.assets_liabilities"
            >
            </b-form-textarea>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import WordExportButton from "./WordExportButton.vue";
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import { mask } from "vue-the-mask";

export default {
  components: { WordExportButton },
  directives: {
    mask,
  },

  created() {
    //this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));

    CommonServices.getItem(this.componentURL)
      .then((resID) => {
        console.log("Current CBR ID: ", resID.results[0].id);
        this.getData(resID.results[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `CBR/?deal_id=${this.$route.params.id}`,
      componentURL_base: `CBR`,

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUser:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),
      lastUpdate: this.$moment(
        String(localStorage.getItem("LastUpdate"))
      ).format("MM-DD-YYYY"),
      lastUpdateTime: localStorage.getItem("LastUpdateTime"),

      word: "",

      items: [],

      //worksheetData: [],
      worksheetData: {
        id: "",
        deal_id: this.$route.params.id,
        deal: "",
        last_upload_date: this.$moment(String(Date())).format("MM/DD/YYYY"),
        last_update_user_id: "",
        biz_dob: null,
        corp_details: "",
        revenue: "",
        market_reason: "",
        competition: "",
        growth: null,
        pros_cons: null,
        holes: null,
        deficiencies: "",
        pipeline: null,
        employees: null,
        key_roles: "",
        owner_role: null,
        biz_detail: "",
        replace_costs: null,
        assets_liabilities: "",
        timestamp: "",
        last_updated: null,
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CBRWorksheet",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    // localStorage.setItem("lastupdate", this.worksheetData.last_updated);
    // console.log("Last Update: ", this.worksheetData.last_updated);
    // console.log("Last Update Time: ", this.lastUpdateTime);
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    openEdit() {
      this.worksheetData.length = 0;
      this.dialogEdit = true;
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.worksheetData);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "CBR Worksheet"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Folder Permissions.xlsx");
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      console.log("Last updated: ", this.worksheetData.last_updated);
      console.log("DealID: ", this.worksheetData.deal_id);
      if (this.worksheetData.last_updated) {
        this.worksheetData.last_updated = this.$moment(
          String(this.worksheetData.last_updated)
        ).format("YYYY-MM-DDThh:mm");
      } else {
        this.worksheetData.last_updated = this.$moment(String(Date())).format(
          "YYYY-MM-DDThh:mm"
        );
      }
      if (this.worksheetData.id) {
        CommonServices.updateData(this.componentURL_base, this.worksheetData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
            this.logActivity(
              "Saved Form Data",
              this.worksheetData.deal_id,
              JSON.stringify(this.worksheetData)
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        CommonServices.addRecord(this.componentURL_base, this.worksheetData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData(cbrID) {
      CommonServices.Detail(cbrID, this.componentURL_base)
        .then((res) => {
          console.log("CBR Detail Response: ", res);
          this.worksheetData = res;
          localStorage.setItem("lastupdate", this.worksheetData.last_updated);
          this.lastUpdate = this.worksheetData.last_updated;
          console.log("Last Update: ", this.worksheetData.last_updated);
          console.log("Last Update Time: ", this.lastUpdateTime);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL_base, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    worksheetDatas(item) {
      this.worksheetData = Object.assign({}, item);
      localStorage.setItem("DealID", item.deal_id);
      console.log("Deal ID:", localStorage.getItem("DealID"));

      this.dialog = true;
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.wide-box-text {
  width: 900px;
  height: 150px;
  border: 2px solid rgb(177, 118, 10);
  padding: 0px;
  margin: 0px;
  font-size: 10px;
  font-weight: 600;
  font-family: Helvetica;
}

.wide-box-text-small {
  width: 300px;
  height: 150px;
  border: 2px solid rgb(177, 118, 10);
  padding: 0px;
  margin: 0px;
  font-size: 5px;
  font-weight: 600;
  font-family: Helvetica;
}

.broad-paragraph {
  height: 200px;
  width: 500px;
  border-color: rgb(172, 114, 6);
}

button {
  color: white;
  height: 36px;
  width: 105px;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 15px;
  cursor: pointer;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
}

.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.add-button {
  background-color: rgba(81, 109, 131, 255);
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.save-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  border-radius: 8px;
  border-radius: 18px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  width: 130px;
}
input#file-input {
  display: none;
}

.test-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  height: 36px;
  width: 155px;
}

.select-button {
  background-color: rgb(66, 5, 51);
  color: white;
  border: none;
  height: 36px;
  width: 155px;
  border-radius: 8px;
  border-radius: 18px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}

.small-lblhead {
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
  line-height: 0pt;
}

.small-bold-input {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 0pt;
  background-color: #03233f13;
}

.small-bold-text {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  background-color: #03233f13;
}

input#file-input + placeholder {
  background-color: #0157a0;
  padding: 5px;
  color: #fff;
  border: 2px solid #85bdee;
  border-radius: 9px;
  line-height: 10pt;
}

input#file-input + placeholder:hover {
  background-color: #20e0a5;
  border-color: #fff;
  cursor: pointer;
}
.err-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: red;
  line-height: 0pt;
}

.small-lbl {
  font-style: italic;
  font-weight: 800;
  font-size: 11px;
  line-height: 0pt;
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}
</style>
